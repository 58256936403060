<template>
	<div class="pdf-viewer">
		<PDFPreview
			v-if="pdf"
			v-show="displayThumbnails"
			class="pdf-viewer__preview"
			:pages="pdfRenderedThumbnails"
			:scale="scale"
			:current-page="page"
			:page-count="totalPages"
			:is-preview-enabled="displayThumbnails"
			@page-focus="setCurrentPage($event)"
		/>
		<PDFDocument
			v-if="pdf"
			class="pdf-viewer__document"
			:class="{ 'preview-enabled': displayThumbnails }"
			:pages="pdfRenderedPages"
			:scale="scale"
			:optimal-scale="optimalScale"
			:fit="fit"
			:current-page="page"
			:page-count="totalPages"
			:is-preview-enabled="displayThumbnails"
			@scale-change="updateScale"
			@page-focus="setCurrentPage($event)"
			@pages-fetch="fetchPages()"
		/>

		<v-layout v-if="zoomButtons" column absolute class="zoom-buttons">
			<v-btn fab dark small color="blue-grey lighten-2" class="mb-3" @click="zoomFit">
				<v-icon dark>fit_screen</v-icon>
			</v-btn>
			<v-btn fab dark small color="blue-grey" @click="zoomIn">
				<v-icon dark>add</v-icon>
			</v-btn>
			<v-btn fab dark small color="blue-grey" @click="zoomOut">
				<v-icon dark>remove</v-icon>
			</v-btn>
		</v-layout>
	</div>
</template>

<script>
import * as PDFJs from 'pdfjs-dist/webpack'

import DocumentVersionsModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard'
import AbstractViewer from '@/components/Documents/Preview/Viewers/AbstractViewer'
import ZoomCatcher from '@/mixins/ZoomCatcher'

const MAX_RENDERED_PAGES = 10
const ZOOM_STEP = 25 / 100
const FIT_MODE_SAVED_KEY = 'PDFViewer-FitMode'

export default {
	name: 'PDFViewer',
	components: {
		PDFDocument: () => ({
			component: import('@/components/Documents/Preview/Viewers/PDFViewer/PDFDocument')
		}),
		PDFPreview: () => ({
			component: import('@/components/Documents/Preview/Viewers/PDFViewer/PDFPreview')
		})
	},
	extends: AbstractViewer,
	mixins: [DocumentVersionsModuleGuard, ZoomCatcher],
	props: {
		displayThumbnails: {
			type: Boolean,
			required: true
		},
		page: {
			type: Number,
			required: true
		},
		version: {
			type: Object,
			required: false,
			default: null
		},
		zoomButtons: {
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			cursor: 0,
			fit: undefined,
			optimalScale: undefined,
			pdf: null,
			pdfRenderedThumbnails: [],
			pdfRenderedPages: [],
			scale: 1,
			totalPages: 1
		}
	},
	watch: {
		page: {
			handler: function () {
				this.fetchPages()
			}
		},
		src: {
			deep: true,
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.loadDocument().then(() => { 
						this.eventBus.emit(this.events.preview.DOCUMENT_RELOADED)
					}).finally(() => {
						this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false)
				})
				}
			},
			immediate: true
		}
	},
	created: function () {
		this.restoreSavedFit()
	},
	destroyed: function () {
		if (this.pdf) {
			this.pdf.destroy()
		}
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.preview.ZOOM_IN, action: this.zoomIn },
				{ event: this.events.preview.ZOOM_OUT, action: this.zoomOut },
				{ event: this.events.preview.ZOOM_FIT, action: this.zoomFit }
			]
		},
		loadDocument: function () {
			this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true)

			return PDFJs.getDocument(this.src)
				.promise.then(pdf => {
					this.resetViewer().then(() => {
						this.pdf = pdf
						this.totalPages = pdf.numPages
						this.setTotalPages(this.totalPages)
					})
				})
				.then(() => {
					return Promise.allSettled([this.fetchPages(), this.fetchThumbnails()])
				})
				
		},
		resetViewer: function () {
			return new Promise(resolve => {
				this.cursor = 0

				if (this.pdf !== null) {
					this.pdf.destroy()
					this.pdf = null
				}

				this.pdfRenderedPages.clear()
				this.pdfRenderedThumbnails.clear()

				resolve()
			})
		},
		restoreSavedFit: function () {
			if (typeof localStorage.getItem(FIT_MODE_SAVED_KEY) !== 'undefined') {
				this.fit = localStorage.getItem(FIT_MODE_SAVED_KEY)
			}
		},
		fetchThumbnails: function () {
			const addedThumbnailsPromises = []
			for (let i = 1; i <= this.totalPages; i++) {
				addedThumbnailsPromises.push(
					this.pdf.getPage(i).then(thumbnail => {
						if (!this.pdfRenderedThumbnails.some(renderedThumbnail => renderedThumbnail._pageIndex == thumbnail._pageIndex)) {
							this.pdfRenderedThumbnails.push(thumbnail)
						}
					})
				)
			}
			return Promise.all(addedThumbnailsPromises)
		},
		fetchPages: function () {
			const minIndex = Math.max(this.cursor == 0 ? this.page - 1 - MAX_RENDERED_PAGES / 2 : this.cursor, 0)
			const maxIndex = Math.min(minIndex + MAX_RENDERED_PAGES, this.totalPages)
			this.cursor = maxIndex
			const addedPagesPromises = []
			for (let i = 1; i <= this.totalPages; i++) {
				if ((minIndex <= i && i <= maxIndex) || this.page == i) {
					addedPagesPromises.push(
						this.pdf.getPage(i).then(newPage => {
							if (!this.pdfRenderedPages.some(renderedPage => renderedPage._pageIndex == newPage._pageIndex)) {
								this.pdfRenderedPages.push(newPage)
							}
						})
					)
				}
			}
			return Promise.all(addedPagesPromises)
		},

		setCurrentPage (pageNumber) {
			this.$emit('page-focus', pageNumber)
		},
		onResetZoom: function () {
			this.zoomFit('height')
		},
		onZoomIn: function () {
			this.zoomIn()
		},
		onZoomOut: function () {
			this.zoomOut()
		},
		updateScale: function ({ scale, isOptimal = false }) {
			const roundedScale = Math.floor(scale * 100) / 100
			if (isOptimal) {
				this.optimalScale = roundedScale
			}
			this.scale = roundedScale
		},
		zoomIn: function () {
			if (this.scale) {
				const newScaleValue = this.scale + ZOOM_STEP
				this.updateScale({ scale: newScaleValue, isOptimal: false })
				this.fit = null
			}
		},
		zoomOut: function () {
			if (this.scale && this.scale > ZOOM_STEP) {
				const newScaleValue = this.scale - ZOOM_STEP
				this.updateScale({ scale: newScaleValue, isOptimal: false })
				this.fit = null
			}
		},
		zoomFit: function (fit) {
			this.fit = fit
			localStorage.setItem(FIT_MODE_SAVED_KEY, this.fit)
		}
	}
}
</script>

<style scoped>
header {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	padding: 1em;
	position: relative;
	z-index: 99;
}

.header-item {
	margin: 0 2.5em;
}

.pdf-viewer .pdf-viewer__document,
.pdf-viewer .pdf-viewer__preview {
	top: 64px;
}

.pdf-viewer__preview {
	display: block;
	width: 15%;
	right: 85%;
}

.pdf-viewer__document {
	top: 64px;
	width: 100%;
	left: 0;
}

.pdf-viewer__document.preview-enabled {
	width: 85%;
	left: 15%;
}

.pdf-viewer .zoom-buttons {
	position: absolute;
	top: 20px;
	right: 40px;
}

@media print {
	header {
		display: none;
	}
}
</style>
